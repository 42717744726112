import React from 'react'
import { Container } from '../components/Container'
import { SEO } from '../components/SEO'
import { Typography } from '../components/Typography'

const FirstNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Platform Services</u>.
      <ol>
        <br />
        <li>
          &nbsp;&nbsp;<u>Access and Use</u>. Subject to and conditioned on You and Your Authorized
          Users&apos; compliance with the terms and conditions of this Agreement, ReturnPro hereby
          grants You a non-exclusive, non-transferable (except as otherwise expressly set forth
          herein): (i) right to access and use the Self-Service Products during the Term, solely for
          use by Authorized Users in accordance with the terms and conditions herein and (ii)
          license to use the Documentation, only during the Term solely for Your internal business
          purposes in connection with Your use of the Self-Service Products. ReturnPro shall provide
          to You the Access Credentials within a reasonable time following the Effective Date.{' '}
          <b>https://www.returnpro.com/pricing (the “Product Schedule”)</b>, sets forth available
          Self-Service Products, the Fees, any additional terms and conditions that apply to such
          applicable Self-Service Products, including usage restrictions or licensing metrics, to
          the extent applicable. You, in Your sole discretion, may grant Retailer Users access to
          your account. ReturnPro shall require all Retailer Users to acknowledge and accept this
          Agreement before accessing Your account, but You acknowledge and agree that ReturnPro has
          no control over such Retailer Users use or misuse of your data and content and ReturnPro
          shall have no liability for the acts and omissions of such Retailer Users.
        </li>
        <li>
          &nbsp;&nbsp;<u>Service and System Control</u>. Except as otherwise expressly provided in
          this Agreement, as between the parties: (a) ReturnPro has and will retain sole control
          over the operation, provision, maintenance, and management of the ReturnPro Materials; and
          (b) You have and will retain sole control over the operation, maintenance, and management
          of, and all access to and use of, Your Systems, and sole responsibility for all access to
          and use of the ReturnPro Materials by any Person by or through Your Systems or any other
          means controlled by You or any Authorized User, including any: (i) information,
          instructions, or materials provided by any of them to the Self-Service Products or
          ReturnPro; (ii) results obtained from any use of the Self-Service Products or ReturnPro
          Materials; and (iii) conclusions, decisions, or actions based on such use.
        </li>
        <li>
          &nbsp;&nbsp;<u>Reservation of Rights</u>. Nothing in this Agreement grants any right,
          title, or interest in or to (including any license under) any Intellectual Property Rights
          in or relating to, the Self-Service Products, ReturnPro Materials, or Third-Party
          Materials, whether expressly, by implication, estoppel, or otherwise. All right, title,
          and interest in and to the Self-Service Products, the ReturnPro Materials, and the
          Third-Party Materials are and will remain with ReturnPro and the respective rights holders
          in the Third-Party Materials.
        </li>
        <li>
          &nbsp;&nbsp;<u>Changes</u>. ReturnPro reserves the right, in its sole discretion, to make
          any change, modify or discontinue to the Self-Service Products and ReturnPro Materials,
          provided that if any such changes, modifications or discontinuation will have a negative
          and material impact to You, ReturnPro shall give You at least thirty days’ prior written
          notice of any such change, modification or discontinuation, except to the extent such
          notice conflicts with applicable Law.
        </li>
        <li>
          &nbsp;&nbsp;<u>Suspension or Termination of Services</u>. ReturnPro may, directly or
          indirectly, and by use of a ReturnPro Disabling Device or any other lawful means, suspend,
          terminate, or otherwise deny You, any Authorized User&apos;s, or any other Person&apos;s
          access to or use of all or any part of the Services or ReturnPro Materials, without
          incurring any resulting obligation or liability, if: (a) ReturnPro receives a judicial or
          other governmental demand or order, subpoena, or law enforcement request that expressly or
          by reasonable implication requires ReturnPro to do so; or (b) ReturnPro believes, in its
          sole discretion, that: (i) You or any Authorized User has failed to comply with any term
          of this Agreement, or accessed or used the Services beyond the scope of the rights granted
          or for a purpose not authorized under this Agreement or in any manner that does not comply
          with any instruction or requirement of the Specifications; (ii) You or any Authorized User
          is, has been, or is likely to be involved in any fraudulent, misleading, or unlawful
          activities; or (iii) this Agreement expires or is terminated. This Section does not limit
          any of ReturnPro&apos;s other rights or remedies, whether at law, in equity, or under this
          Agreement.
        </li>
      </ol>
    </li>
  )
}

const TwoNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Use Restriction</u>. You shall not, and shall not permit any other Person to,
      access or use the ReturnPro Materials except as expressly permitted by this Agreement and, in
      the case of Third-Party Materials, the applicable third-party license agreement. For purposes
      of clarity and without limiting the generality of the foregoing, You shall not, except as this
      Agreement expressly permits: (a) copy, modify, or create derivative works or improvements of
      the ReturnPro Materials; (b) rent, lease, lend, sell, sublicense, assign, distribute, publish,
      transfer, or otherwise make available any ReturnPro Materials to any Person, including on or
      in connection with the internet or any time-sharing, service bureau, software as a service,
      cloud, or other technology or service; (c) reverse engineer, disassemble, decompile, decode,
      adapt, or otherwise attempt to derive or gain access to the source code of the ReturnPro
      Materials, in whole or in part; (d) bypass or breach any security device or protection used by
      the ReturnPro Materials or access or use the ReturnPro Materials other than by an Authorized
      User through the use of his or her own then valid Access Credentials; (e) input, upload,
      transmit, or otherwise provide to or through the ReturnPro Systems, any information or
      materials that are unlawful or injurious, or contain, transmit, or activate any Harmful Code;
      (f) damage, destroy, disrupt, disable, impair, interfere with, or otherwise impede or harm in
      any manner the ReturnPro Systems, or ReturnPro&apos;s provision of services to any third
      party, in whole or in part; (g) remove, delete, alter, or obscure any trademarks,
      Specifications, Documentation, warranties, or disclaimers, or any copyright, trademark,
      patent, or other intellectual property or proprietary rights notices from any ReturnPro
      Materials, including any copy thereof; (h) access or use the ReturnPro Materials in any manner
      or for any purpose that infringes, misappropriates, or otherwise violates any Intellectual
      Property Right or other right of any third party, or that violates any applicable Law; (i)
      access or use the ReturnPro Materials for purposes of competitive analysis of the ReturnPro
      Materials, the development, provision, or use of a competing software service or product or
      any other purpose that is to the ReturnPro&apos;s detriment or commercial disadvantage; or (j)
      otherwise access or use the ReturnPro Materials beyond the scope of the authorization granted
      under this Section.
    </li>
  )
}

const ThirdNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Consignment Services</u>.
      <ol>
        <br />
        <li>
          &nbsp;&nbsp;<u>Appointment and Acceptance</u>. The Self-Service Products may include
          consignment related services, and to the extent that You choose to purchase such
          Self-Service Products, You hereby appoint ReturnPro on a non-exclusive basis as an
          authorized consignee of Your Products as set forth in this Agreement. ReturnPro accepts
          such appointment and agrees to act in such capacity as described herein. ReturnPro shall
          have the right to obtain or retain the rights to act as a consignee or distributor of any
          other products, including products that may compete with Your Products.
        </li>
        <li>
          &nbsp;&nbsp;<u>Delivery of Consigned Products</u>. Through the Self-Service Products, You
          may designate ReturnPro as the consignee of consigned stock or inventory of products, and
          any additional products You submit through the Self-Service Products from time to time
          (&quot;Your Products&quot;). Deliveries of Your Products to ReturnPro on a consignment
          basis hereunder shall be made at Your sole expense and all shipments of Your Products to
          ReturnPro shall be F.O.B. ReturnPro Locations. You shall inform ReturnPro in writing of a
          way bill number for each shipment of Your Products by You to the Locations and shall
          provide ReturnPro with such other information as ReturnPro may reasonably request in order
          to allow ReturnPro to track and identify shipments of consigned Products under the terms
          of this Agreement. Upon receipt by ReturnPro of any Products for consignment, ReturnPro
          shall conduct a preliminary incoming inspection of the Products to verify whether
          ReturnPro has received the quantity of the Products reportedly sent to ReturnPro as
          indicated on Your packing list, and to detect any visible damage to the boxes or packaging
          of the Products. ReturnPro shall use reasonable efforts to notify You in writing of any
          damage to the Products as delivered, any shortage in the number of boxes delivered, or any
          other non-conformity of the delivery to the request originally made by ReturnPro.
        </li>
        <li>
          &nbsp;&nbsp;<u>Storage and Care of Consigned Products</u>. The consigned Products shall be
          stored at ReturnPro, or its third-party service provider’s, facilities as determined in
          ReturnPro’s sole discretion, designated by ReturnPro upon acceptance to act as a consignee
          for Your Products (the &quot;Locations&quot;). Your Products shall be separated from
          ReturnPro&apos;s other products, supplies, and equipment at the Locations, and shall be
          marked to indicate Your ownership of Your Products. ReturnPro shall use reasonable efforts
          to provide security for Your Products at the Locations. Your Products may be covered by
          ReturnPro&apos;s insurance while at the Locations, but ReturnPro shall have no obligation
          to insure Your Products and Your insurance shall be primary for the loss or damage to Your
          Products at all times prior to the sale of Your Products, subject to the shrinkage
          allowance. You agree that ReturnPro is entitled to a shrinkage allowance that must be
          exceeded prior to ReturnPro having liability for any loss, damage, or shortages of Your
          Products (the &quot;Shrinkage Allowance&quot;). The Shrinkage Allowance will be 3% of the
          total throughput of all Locations since the last Shrinkage Allowance count, or in the
          absence of a previous Shrinkage Allowance count, since the origination of the Agreement.
          Throughput means the aggregate sum of the resale value of Your Products in the condition
          as received by ReturnPro. Overages and shortages of goods will be netted against one
          another to determine the net shrinkage value. A claim may only be filed in the event of a
          net shortage. You may request a Shrinkage Allowance count no more than once in any
          calendar year and You shall be responsible for the cost and expense of such count.
        </li>
        <li>
          &nbsp;&nbsp;<u>Title</u>. You hereby agree that at no time during the period that Your
          Products are held by ReturnPro as consigned inventory will ReturnPro hold title, or any
          other rights of ownership in Your Products. Title in Your Products will continue to be
          held by You until such time as the Products are delivered to its final destination,
          whether returned to you, sold to an end user, or otherwise disposed of, unless otherwise
          set forth in the Product Schedule.
        </li>
        <li>
          &nbsp;&nbsp;<u>Returns</u>. If any of Your Product(s) has not been sold or distributed
          through any channel to a third-party within ninety days following ReturnPro’s receipt of
          Your Product, then ReturnPro will provide you with an option to: (i) return to You, at
          Your sole expense (F.O.B. ReturnPro Locations), any of Your Products consigned to
          ReturnPro; or (ii) liquidate Your Products through any channel and by any means at any
          price ReturnPro deems appropriate; or (iii) upon written agreement from ReturnPro, extend
          storage for an additional ninety days for a price determined as part of the written
          agreement from ReturnPro.
        </li>
      </ol>
    </li>
  )
}

const FourthNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Your Sales Channels & ReturnPro Sales Channels</u>. The Product Schedule
      identifies the Self-Service Products under which ReturnPro will act as the seller through your
      e-commerce platform or through your account with a third-party marketplace. In those
      circumstances, ReturnPro shall use commercially reasonable efforts to protect such information
      and only disclose your access credentials on a need-to-know basis. You shall remain liable for
      all: (i) costs and expenses related to your and your third-party ecommerce platform accounts,
      and (ii) acts and omissions taken under your and your third-party ecommerce accounts by you or
      by ReturnPro on your behalf. ReturnPro may also sell Your Products under ReturnPro’s owned and
      controlled ecommerce channels or ReturnPro’s accounts with third party ecommerce channel. In
      those circumstances, ReturnPro shall be responsible for: (i) all cost and expenses under
      ReturnPro’s ecommerce accounts, including third-party ecommerce accounts and (ii) the
      collection of payments and taxes from the applicable buyer, subject to Your payment of the
      fees set forth in the Product Schedule.
    </li>
  )
}

const FifthNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Your Obligations</u>.
      <ol>
        <br />
        <li>
          &nbsp;&nbsp;<u>Your Systems and Cooperation</u>. You shall at all times during the Term:
          (a) set up, maintain, and operate in good repair and in accordance with the Specifications
          all of Your Systems on or through which the Self-Service Products are accessed or used;
          (b) provide ReturnPro Personnel with such access to Your premises and Your Systems as is
          necessary for ReturnPro to perform or provide the Self-Service Products in accordance with
          the Availability Requirement and Specifications; and (c) provide all cooperation and
          assistance as ReturnPro may reasonably request to enable ReturnPro to exercise its rights
          and perform its obligations under and in connection with this Agreement. At no charge to
          ReturnPro, You shall support Your Products and any efforts to sell Your Products by
          ReturnPro, and provide sales literature, advertising materials and reasonable training and
          support in the sale and use of Your Products to employees and customers of ReturnPro, if
          requested to do so by ReturnPro. ReturnPro may advertise and promote Your Products in a
          commercially reasonable manner and may use Your or any third-party owner’s trademarks,
          service marks and trade names in connection therewith. You agree to cooperate with
          ReturnPro in advertising and promoting Your Products, to the extent necessary.
        </li>
        <li>
          &nbsp;&nbsp;<u>Effect of You Failure or Delay</u>. ReturnPro is not responsible or liable
          for any delay or failure of performance caused in whole or in part by Your delay in
          performing, or failure to perform, any of Your obligations under this Agreement.
        </li>
        <li>
          &nbsp;&nbsp;<u>Corrective Action and Notice</u>. If You become aware of any actual or
          threatened prohibited activity, You shall, and shall cause Your Authorized Users to,
          immediately: (a) take all reasonable and lawful measures within their respective control
          that are necessary to stop the activity or threatened activity and to mitigate its effects
          (including, where applicable, by discontinuing and preventing any unauthorized access to
          the ReturnPro Materials and permanently erasing from their systems and destroying any data
          to which any of them have gained unauthorized access); and (b) notify ReturnPro of any
          such actual or threatened activity.
        </li>
      </ol>
    </li>
  )
}

const SixthNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Self-Service Product Support</u>. The Self-Service Products include
      ReturnPro&apos;s standard support services (&quot;Support Services&quot;) in accordance with
      the ReturnPro service support schedule then in effect, a current copy of which is available at
      https://www.returnpro.com/supportpolicy (or a successor website address) (the{' '}
      <b>&quot;Support Exhibit&quot;</b>). ReturnPro may amend the Support Exhibit from time to time
      in its sole discretion.
    </li>
  )
}

const SeventhNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Security</u>. ReturnPro will employ commercially reasonable security measures
      to protect your Confidential Information. Notwithstanding the foregoing, You understand that
      the operation of the Self-Service Products including Your Data,, may be unencrypted and
      involve (a) transmissions over various networks; (b) changes to conform and adapt to technical
      requirements of connecting networks or devices and (c) transmission to ReturnPro’s third party
      vendors and hosting partners to provide the necessary delivery, storage, fulfillment,
      hardware, software, networking, and related technology required to operate and maintain the
      Self-Service Products. Accordingly, You acknowledge that you bear sole responsibility for
      adequate security, protection, and backup of Your Data. ReturnPro will have no liability to
      You for any unauthorized access or use of any of Your Data. You have and will retain sole
      responsibility for: (a) all of Your Data, including Your content and use; (b) all information,
      instructions, and materials provided by or on behalf of You or any Authorized User in
      connection with the Self-Service Products; (c) Your information technology infrastructure,
      including computers, software, databases, electronic systems (including database management
      systems), and networks, whether operated directly by You or through the use of third-party
      services (&quot;Your Systems&quot;); (d) the security and use of Your and Your Authorized
      Users&apos; Access Credentials; and (e) all access to and use of the Services and ReturnPro
      Materials directly or indirectly by or through Your Systems or Your or Your Authorized
      Users&apos; Access Credentials, with or without Your knowledge or consent, including all
      results obtained from, and all conclusions, decisions, and actions based on, such access or
      use.
    </li>
  )
}

const EighthNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Fees and Payment</u>.
      <ol>
        <br />
        <li>
          &nbsp;&nbsp;<u>Fees</u>. You shall pay ReturnPro the fees set forth in{' '}
          <b>the Product Schedule</b> or as otherwise offered to you at the time you purchase such
          Self-Service Products (&quot;Fees&quot;) in accordance with this Section. Unless otherwise
          stated: (i) all subscription and software based Fees are due either monthly or annually
          upfront, dependent on your chosen subscription level; (ii) all supply chain service Fees
          shall be billed against a replenishing deposit account created and funded by You that will
          have a minimum balance determined by ReturnPro (“Minimum Balance”) and replenished monthly
          to the same Minimum Balance to cover all future supply chain service Fees; and (iii) all
          recommerce service Fees shall be: (y) billed against a replenishing deposit account
          created and funded by You that will have a Minimum Balance to cover all recommerce Fees
          owed by you for Your Product sales through Your sales channels, or (z) processed by
          ReturnPro for all sales through ReturnPro’s sales channels, and either paid directly back
          to You or deposited in Your deposit account all remaining funds after deducting processing
          fees, advertising fees, recovery fees and other costs and expenses for the sale of Your
          Products. You shall have a credit card on file with ReturnPro or other form of payment
          acceptable to ReturnPro to cover all agreed upon Fees. All credit card transactions will
          include a credit card processing fee equal to 3% fees paid by credit card. You shall make
          all payments hereunder in US dollars. You authorize ReturnPro to charge Your credit card
          for any charges related to any Self-Service Products you order. You may revoke credit card
          authorization for future payment obligations by notifying ReturnPro in writing via
          support@returnpro.com, provided, however such revocation shall not entitle You to any
          refund for previously paid Fees.
        </li>
        <li>
          &nbsp;&nbsp;<u>Taxes</u>. All Fees and other amounts payable by You under this Agreement
          are exclusive of taxes and similar assessments. Without limiting the foregoing, You are
          responsible for all sales, use, and excise taxes, and any other similar taxes, duties, and
          charges of any kind imposed by any federal, state, or local governmental or regulatory
          authority on any amounts payable by You hereunder, other than any taxes imposed on
          ReturnPro&apos;s income. Notwithstanding the foregoing, ReturnPro shall pay the sales tax
          on Your behalf for all recommerce services in which ReturnPro sells Your Products through
          ReturnPro’s sales channels and processes the customer payments.
        </li>
        <li>
          &nbsp;&nbsp;<u>Late Payment</u>. If You fail to make any payment when due then, in
          addition to all other remedies that may be available: (a) ReturnPro may charge interest on
          the past due amount at the rate of 1.5% per month calculated daily and compounded monthly
          or, if lower, the highest rate permitted under applicable Law; (b) You shall reimburse
          ReturnPro for all costs incurred by ReturnPro in collecting any late payments or interest,
          including attorneys&apos; fees, court costs, and collection agency fees; and (c) if such
          failure continues for 30 days following written notice thereof, ReturnPro may suspend
          performance of the Self-Service Products until all past due amounts and interest thereon
          have been paid, without incurring any obligation or liability to You or any other Person
          by reason of such suspension. In addition, if You fail to pay any Fees when due, and if
          not remedied after ReturnPro has provided a notice of breach, ReturnPro may hold Your
          Products as collateral, or sell Your Products through any channel for any fee that
          ReturnPro deems reasonable for Your Products, to cover all unpaid Fees.
        </li>
        <li>
          &nbsp;&nbsp;<u>No Deductions or Setoffs</u>. All amounts payable to ReturnPro under this
          Agreement shall be paid by You to ReturnPro in full without any setoff, recoupment,
          counterclaim, deduction, debit, or withholding for any reason (other than any deduction or
          withholding of tax as may be required by applicable Law).
        </li>
        <li>
          &nbsp;&nbsp;<u>Fee Increases</u>. ReturnPro may increase Fees set forth in the Product
          Schedule at any time in its sole discretion.
        </li>
      </ol>
    </li>
  )
}

const NinthNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Confidentiality</u>.
      <ol>
        <br />
        <li>
          &nbsp;&nbsp;<u>Confidential Information</u>. In connection with this Agreement each party
          (as the &quot;Disclosing Party&quot;) may disclose or make available Confidential
          Information to the other party (as the &quot;Receiving Party&quot;). Subject to Section
          9.2, &quot;Confidential Information&quot; means information in any form or medium (whether
          oral, written, electronic, or other) that the Disclosing Party considers confidential or
          proprietary, including information consisting of or relating to the Disclosing
          Party&apos;s technology, trade secrets, know-how, business operations, plans, strategies
          and pricing, in each case whether or not marked, designated, or otherwise identified as
          &quot;confidential&quot;.
        </li>
        <li>
          &nbsp;&nbsp;<u>Exclusions</u>. Confidential Information does not include information that:
          (a) was rightfully known to the Receiving Party without restriction on use or disclosure
          prior to such information&apos;s being disclosed or made available to the Receiving Party
          in connection with this Agreement; (b) was or becomes generally known by the public other
          than by the Receiving Party&apos;s or any of its Representatives&apos; noncompliance with
          this Agreement; (c) was or is received by the Receiving Party on a non-confidential basis
          from a third party that, to the Receiving Party&apos;s knowledge, was not or is not, at
          the time of such receipt, under any obligation to maintain its confidentiality; or (d) the
          Receiving Party can demonstrate by written or other documentary records was or is
          independently developed by the Receiving Party without reference to or use of any
          Confidential Information.
        </li>
        <li>
          &nbsp;&nbsp;<u>Protection of Confidential Information</u>. As a condition to being
          provided with any disclosure of or access to Confidential Information, the Receiving Party
          shall for a period of five years following expiration or termination of this Agreement:
          (a) not access or use Confidential Information other than as necessary to exercise its
          rights or perform its obligations under and in accordance with this Agreement; (b) except
          as may be permitted below, not disclose or permit access to Confidential Information other
          than to its Representatives who: (i) need to know such Confidential Information for
          purposes of the Receiving Party&apos;s exercise of its rights or performance of its
          obligations under and in accordance with this Agreement; (ii) have been informed of the
          confidential nature of the Confidential Information and the Receiving Party&apos;s
          obligations under this Section; and (iii) are bound by confidentiality and restricted use
          obligations at least as protective of the Confidential Information as the terms set forth
          in this Section; (c) safeguard the Confidential Information from unauthorized use, access,
          or disclosure using at least the degree of care it uses to protect its similarly sensitive
          information and in no event less than a reasonable degree of care; (d) promptly notify the
          Disclosing Party of any unauthorized use or disclosure of Confidential Information and
          take all reasonable steps to prevent further unauthorized use or disclosure; and (e)
          ensure its Representatives&apos; compliance with, and be responsible and liable for any of
          its Representatives&apos; non-compliance with, the terms of this Section.
        </li>
        <li>
          &nbsp;&nbsp;<u>Compelled Disclosures</u>. If the Receiving Party or any of its
          Representatives is compelled by applicable Law to disclose any Confidential Information
          then, to the extent permitted by applicable Law, the Receiving Party shall: (a) promptly,
          and prior to such disclosure, notify the Disclosing Party in writing of such requirement
          so that the Disclosing Party can seek a protective order or other remedy or waive its
          rights under Section 9.3; and (b) provide reasonable assistance to the Disclosing Party,
          in opposing such disclosure or seeking a protective order or other limitations on
          disclosure. If the Disclosing Party waives compliance or, after providing the notice and
          assistance required under this Section 9.4, the Receiving Party remains required by Law to
          disclose any Confidential Information, the Receiving Party shall disclose only that
          portion of the Confidential Information that the Receiving Party is legally required to
          disclose.
        </li>
      </ol>
    </li>
  )
}

const TenthNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Intellectual Property Rights</u>.
      <ol>
        <br />
        <li>
          &nbsp;&nbsp;<u>ReturnPro Materials</u>. All right, title, and interest in and to the
          ReturnPro Materials, including all Intellectual Property Rights therein, are and will
          remain with ReturnPro and, with respect to Third-Party Materials, the applicable
          third-party. You have no right, license, or authorization with respect to any of the
          ReturnPro Materials except as expressly set forth under this Agreement or the applicable
          third-party license. All other rights in and to the ReturnPro Materials are expressly
          reserved by ReturnPro. In furtherance of the foregoing, You hereby unconditionally and
          irrevocably grant to ReturnPro an assignment of all right, title, and interest in and to
          the Resultant Data, including all Intellectual Property Rights relating thereto.
        </li>
        <li>
          &nbsp;&nbsp;<u>Your Data</u>. As between You and ReturnPro, You are and will remain the
          sole and exclusive owner of all right, title, and interest in and to all Your Data,
          including all Intellectual Property Rights relating thereto, subject to the rights and
          permissions granted in Section 10.3.
        </li>
        <li>
          &nbsp;&nbsp;<u>Consent to Use Your Data</u>. You hereby irrevocably grant ReturnPro a
          worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable and
          transferable license to use, modify, reproduce, distribute, display, publish and perform
          Your Data and all such rights and permissions in or relating to Your Data as are necessary
          or useful to ReturnPro, its Subcontractors, the ReturnPro Personnel, and their
          contractors, agents and partners, to enforce this Agreement, exercise the rights and
          perform the obligations hereunder, or otherwise improve, modify or add Services.
        </li>
      </ol>
    </li>
  )
}

const EleventhNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Representations and Warranties</u>.
      <ol>
        <br />
        <li>
          &nbsp;&nbsp;<u>ReturnPro Representations, Warranties, and Covenants</u>. ReturnPro
          represents, warrants, and covenants to You that ReturnPro will perform the Services using
          personnel of required skill, experience, and qualifications and in a professional and
          workmanlike manner in accordance with generally recognized industry standards for similar
          services and will devote adequate resources to meet its obligations under this Agreement.
        </li>
        <li>
          &nbsp;&nbsp;<u>Your Representations, Warranties, and Covenants</u>. You represent,
          warrant, and covenant to ReturnPro that You own or otherwise have and will have the
          necessary rights and consents in and relating to Your Data so that, as received by
          ReturnPro and Processed in accordance with this Agreement, they do not and will not
          infringe, misappropriate, or otherwise violate any Intellectual Property Rights, or any
          privacy or other rights of any third party or violate any applicable Law.
        </li>
        <li>
          &nbsp;&nbsp;<u>DISCLAIMER OF WARRANTIES</u>. EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH
          IN, ALL SELF-SERVICE PRODUCTS AND RETURNPRO MATERIALS ARE PROVIDED &quot;AS IS.&quot;
          RETURNPRO SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF
          DEALING, USAGE, OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, RETURNPRO MAKES NO
          WARRANTY OF ANY KIND THAT THE SELF-SERVICE PRODUCTS OR RETURNPRO MATERIALS, OR ANY
          PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET YOUR OR ANY OTHER PERSON&apos;S
          REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR
          WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE
          OF HARMFUL CODE, OR ERROR FREE. ALL THIRD-PARTY MATERIALS ARE PROVIDED &quot;AS IS&quot;
          AND ANY REPRESENTATION OR WARRANTY OF OR CONCERNING ANY THIRD-PARTY MATERIALS IS STRICTLY
          BETWEEN YOU AND THE THIRD-PARTY OWNER OR DISTRIBUTOR OF THE THIRD-PARTY MATERIALS.
        </li>
      </ol>
    </li>
  )
}

const TwelfthNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Indemnification</u>. You shall indemnify, defend, and hold harmless ReturnPro
      and its Subcontractors and Affiliates, and each of its and their respective officers,
      directors, employees, agents, successors, and assigns (each, a{' '}
      <b>&quot;ReturnPro Indemnitee&quot;</b>) from and against any and all Losses incurred by such
      ReturnPro Indemnitee resulting from any Action by a third party (other than an Affiliate of a
      ReturnPro Indemnitee) that arise out of or result from, or are alleged to arise out of or
      result from: (a) Your Data or Your Products; (b) any allegation of facts that, if true, would
      constitute Your breach of any of Your representations, warranties, covenants, or obligations
      under this Agreement; (c) sale or display of Your Products; (d) use of Your Products and any
      damages resulting therefrom.
    </li>
  )
}

const ThirteenthNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Limitations of Liability</u>.
      <ol>
        <br />
        <li>
          &nbsp;&nbsp;<u>EXCLUSION OF DAMAGES</u>. IN NO EVENT WILL RETURNPRO OR ANY OF ITS
          LICENSORS OR SUPPLIERS BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT
          MATTER UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
          NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY: (a) LOSS OF PRODUCTION, USE,
          BUSINESS, REVENUE, OR PROFIT OR DIMINUTION IN VALUE; (b) IMPAIRMENT, INABILITY TO USE OR
          LOSS, INTERRUPTION, OR DELAY OF THE SERVICES; (c) LOSS, DAMAGE, CORRUPTION, OR RECOVERY OF
          DATA, OR BREACH OF DATA OR SYSTEM SECURITY; (d) COST OF REPLACEMENT GOODS OR SERVICES; (e)
          LOSS OF GOODWILL OR REPUTATION; OR (f) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY,
          SPECIAL, ENHANCED, OR PUNITIVE DAMAGES, REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF
          THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE
          FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS
          ESSENTIAL PURPOSE. RETURNPRO IS NOT RESPONSIBLE FOR (A) ANY ERRORS OR OMMISSIONS ARISING
          FROM OR RELATED TO LISTING, SELLING, DISTRIBURING, STORING OR DISPOSING OF YOUR PRODUCTS,
          OR (B) LOSSES OR DAMAGES ARISING FROM THE USE OF YOUR PRODUCTS BY ANY PERSON.
        </li>
        <li>
          &nbsp;&nbsp;<u>CAP ON MONETARY LIABILITY</u>. IN NO EVENT WILL THE AGGREGATE LIABILITY OF
          RETURNPRO AND ITS LICENSORS AND SUPPLIERS ARISING OUT OF OR RELATED TO THIS AGREEMENT,
          WHETHER ARISING UNDER OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE),
          STRICT LIABILITY, OR ANY OTHER LEGAL OR EQUITABLE THEORY, EXCEED THE TOTAL AMOUNTS PAID TO
          RETURNPRO UNDER THIS AGREEMENT IN THE 6 MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO
          THE CLAIM. THE FOREGOING LIMITATIONS APPLY EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL
          PURPOSE.
        </li>
      </ol>
    </li>
  )
}

const FourteenthNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Term and Termination</u>.
      <ol>
        <br />
        <li>
          &nbsp;&nbsp;<u>Term</u>. The term of this Agreement commences as of the Effective Date
          and, unless terminated earlier pursuant any of the Agreement&apos;s express provisions,
          will continue in effect on a year to year basis (unless otherwise set forth in the Product
          Schedule) until either party gives the other party written notice of non-renewal at least
          30 days prior the renewal of the initial annual term or any renewal thereof, that will
          take effect at the end of the contract term marked by the end of such notice period (the{' '}
          <b>&quot;Term&quot;</b>).
        </li>
        <li>
          &nbsp;&nbsp;<u>Termination</u>. In addition to any other express termination right set
          forth elsewhere in this Agreement: (a) either party may terminate this Agreement,
          effective on written notice to the other party, if the other party materially breaches
          this Agreement, and such breach: (i) is incapable of cure; or (ii) being capable of cure,
          remains uncured 30 days after the non-breaching party provides the breaching party with
          written notice of such breach; and (b) either party may terminate this Agreement,
          effective immediately upon written notice to the other party, if the other party: (i)
          becomes insolvent or is generally unable to pay, or fails to pay, its debts as they become
          due; (ii) files, or has filed against it, a petition for voluntary or involuntary
          bankruptcy or otherwise becomes subject, voluntarily or involuntarily, to any proceeding
          under any domestic or foreign bankruptcy or insolvency Law; (iii) makes or seeks to make a
          general assignment for the benefit of its creditors; or (iv) applies for or has appointed
          a receiver, trustee, custodian, or similar agent appointed by order of any court of
          competent jurisdiction to take charge of or sell any material portion of its property or
          business.
        </li>
        <li>
          &nbsp;&nbsp;<u>Effect of Termination or Expiration</u>. Upon any expiration or termination
          of this Agreement, except as expressly otherwise provided in this Agreement: (a) You shall
          immediately cease all use of any ReturnPro Materials and (i) promptly return to ReturnPro,
          or at ReturnPro&apos;s written request destroy, all documents and tangible materials
          containing, reflecting, incorporating, or based on any ReturnPro Materials or
          ReturnPro&apos;s Confidential Information; (ii) permanently erase all ReturnPro Materials
          and ReturnPro&apos;s Confidential Information from all systems You directly or indirectly
          controls; and (iii) certify to ReturnPro in a signed written instrument that You have
          complied with the requirements of this Section; and (b) ReturnPro may disable all Your and
          Authorized User access to the ReturnPro Materials.
        </li>
        <li>
          &nbsp;&nbsp;<u>Surviving Terms</u>. Any right or obligation of the parties in this
          Agreement that, by its nature, should survive termination or expiration of this Agreement,
          will survive any expiration or termination of this Agreement.
        </li>
      </ol>
    </li>
  )
}

const FifteenthNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Retailer Users</u>. If you accept the terms and conditions of this Agreement to
      act as a Retailer User for a third-party Person, you acknowledge and agree that you are an
      Authorized User for such third-party Person granting you access to the Service and agree to
      comply with the terms and conditions of this Agreement provided that no Retailer User shall
      have any financial responsibility or payment obligations to ReturnPro in their role as a
      Retailer User. If there is a dispute between You and a Retailer User, or between you and any
      third party, You understand and agree that ReturnPro is under no obligation to become
      involved. In the event that You have a dispute with any Retailer User, you hereby releases
      ReturnPro, its affiliates and its respective agents, contractors, subcontractors, employees,
      officers, directors, shareholders, partners, and members, from all claims, demands, and
      damages (actual and consequential) of every kind or nature, known and unknown, suspected and
      unsuspected, disclosed and undisclosed, arising out of or in any way related to such dispute.
    </li>
  )
}

const SixteenthNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Miscellaneous</u>.
      <ol>
        <br />
        <li>
          &nbsp;&nbsp;<u>Relationship of the Parties</u>. The relationship between the parties is
          that of independent contractors. Nothing contained in this Agreement shall be construed as
          creating any agency, partnership, joint venture, or other form of joint enterprise,
          employment, or fiduciary relationship between the parties, and neither party shall have
          authority to contract for or bind the other party in any manner whatsoever.
        </li>
        <li>
          &nbsp;&nbsp;<u>Notices</u>. Any notice, request, consent, claim, demand, waiver, or other
          communications under this Agreement have legal effect only if in writing and addressed to
          a party as follows: if to ReturnPro, 20200 W Dixie Hwy, Suite 1005, Miami, FL 33180, and
          if to You, to the email address on file in Your account. Notices sent in accordance with
          this Section will be deemed effectively given: (a) when received, if delivered by hand,
          with signed confirmation of receipt; (b) when received, if sent by a nationally recognized
          overnight courier, signature required; (c) when sent, if by facsimile or email, (with
          confirmation of transmission), if sent during the addressee&apos;s normal business hours,
          and on the next business day, if sent after the addressee&apos;s normal business hours;
          and (d) on the fifth day after the date mailed by certified or registered mail, return
          receipt requested, postage prepaid.
        </li>
        <li>
          &nbsp;&nbsp;<u>Interpretation</u>. For purposes of this Agreement: (a) the words
          &quot;include,&quot; &quot;includes,&quot; and &quot;including&quot; are deemed to be
          followed by the words &quot;without limitation&quot;; (b) the word &quot;or&quot; is not
          exclusive; (c) the words &quot;herein,&quot; &quot;hereof,&quot; &quot;hereby,&quot;
          &quot;hereto,&quot; and &quot;hereunder&quot; refer to this Agreement as a whole; (d)
          words denoting the singular have a comparable meaning when used in the plural, and
          vice-versa; and (e) words denoting any gender include all genders. Unless the context
          otherwise requires, references in this Agreement: (x) to sections, exhibits, schedules,
          attachments, and appendices mean the sections of, and exhibits, schedules, attachments,
          and appendices attached to, this Agreement; (y) to an agreement, instrument, or other
          document means such agreement, instrument, or other document as amended, supplemented, and
          modified from time to time to the extent permitted by the provisions thereof; and (z) to a
          statute means such statute as amended from time to time and includes any successor
          legislation thereto and any regulations promulgated thereunder. The parties intend this
          Agreement to be construed without regard to any presumption or rule requiring construction
          or interpretation against the party drafting an instrument or causing any instrument to be
          drafted. The exhibits, schedules, attachments, and appendices referred to herein are an
          integral part of this Agreement to the same extent as if they were set forth verbatim
          herein.
        </li>
        <li>
          &nbsp;&nbsp;<u>Assignment</u>. You shall not assign or otherwise transfer any of its
          rights, or delegate or otherwise transfer any of its obligations or performance under this
          Agreement, in each case whether voluntarily, involuntarily, by operation of law, or
          otherwise, without ReturnPro&apos;s prior written consent.
        </li>
        <li>
          &nbsp;&nbsp;<u>Force Majeure</u>. In no event will ReturnPro be liable or responsible to
          You, or be deemed to have defaulted under or breached this Agreement, for any failure or
          delay in fulfilling or performing any term of this Agreement (except for any obligations
          to make payments), when and to the extent such failure or delay is caused by any
          circumstances beyond such party&apos;s reasonable control (a &quot;Force Majeure
          Event&quot;), including (i) acts of God; (ii) flood, fire, earthquake, epidemics,
          pandemics, or explosion; (iii) war, invasion, hostilities (whether war is declared or
          not), terrorist threats or acts, riot or other civil unrest; (iv) government order, law,
          or actions; (v) embargoes or blockades in effect on or after the date of this Agreement;
          and (vi) national or regional emergency; and (vii) shortage of adequate power or
          transportation facilities.
        </li>
        <li>
          &nbsp;&nbsp;<u>No Third-Party Beneficiaries</u>. This Agreement is for the sole benefit of
          the parties hereto and their respective successors and permitted assigns and nothing
          herein, express or implied, is intended to or shall confer upon any other Person any legal
          or equitable right, benefit, or remedy of any nature whatsoever under or by reason of this
          Agreement. You shall remain responsible and liable for all acts and omissions of any
          Authorized User. No Authorized User may make a claim against ReturnPro hereunder.
        </li>
        <li>
          &nbsp;&nbsp;<u>Subcontractors</u>. ReturnPro may from time to time in its discretion
          engage third parties to perform Self-Service Products (each, a &quot;Subcontractor&quot;).
          ReturnPro shall remain responsible for all Subcontractors hereunder.
        </li>
        <li>
          &nbsp;&nbsp;<u>Amendment and Modification; Waiver</u>. No amendment to or modification of
          or rescission, termination, or discharge of this Agreement is effective unless it is in
          writing and signed by an authorized representative of each party. No waiver by ReturnPro
          of any of the provisions hereof shall be effective unless explicitly set forth in writing
          and signed by ReturnPro so waiving.
        </li>
        <li>
          &nbsp;&nbsp;<u>Severability</u>. If any term or provision of this Agreement is invalid,
          illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or
          unenforceability shall not affect any other term or provision of this Agreement or
          invalidate or render unenforceable such term or provision in any other jurisdiction. Upon
          such determination that any term or other provision is invalid, illegal, or unenforceable,
          the parties hereto shall negotiate in good faith to modify this Agreement so as to effect
          the original intent of the parties as closely as possible in a mutually acceptable manner
          in order that the transactions contemplated hereby be consummated as originally
          contemplated to the greatest extent possible.
        </li>
        <li>
          &nbsp;&nbsp;<u>Governing Law; Submission to Jurisdiction</u>. This Agreement is governed
          by and construed in accordance with the internal laws of the State of Florida without
          giving effect to any choice or conflict of law provision or rule that would require or
          permit the application of the laws of any jurisdiction other than those of the State of
          Florida. Any legal suit, action, or proceeding arising out of or related to this Agreement
          or the licenses granted hereunder will be instituted exclusively in the federal courts of
          the United States or the courts of the State of Florida in each case located in the city
          of Miami and County of Miami-Dade, and each party irrevocably submits to the exclusive
          jurisdiction of such courts in any such suit, action, or proceeding. Service of process,
          summons, notice, or other document by mail to such party&apos;s address set forth herein
          shall be effective service of process for any suit, action, or other proceeding brought in
          any such court.
        </li>
        <li>
          &nbsp;&nbsp;<u>Equitable Relief</u>. You acknowledges and agrees that a breach or
          threatened breach by You of with respect to any of ReturnPro’s Intellectual Property
          Rights or Confidential Information, would cause ReturnPro irreparable harm for which
          monetary damages would not be an adequate remedy and that, in the event of such breach or
          threatened breach, ReturnPro will be entitled to equitable relief, including a restraining
          order, an injunction, specific performance, and any other relief that may be available
          from any court, without any requirement to post a bond or other security, or to prove
          actual damages or that monetary damages are not an adequate remedy. Such remedies are not
          exclusive and are in addition to all other remedies that may be available at law, in
          equity, or otherwise.
        </li>
        <li>
          &nbsp;&nbsp;<u>Feedback</u>. To the extent You provide any suggestions, enhancement
          requests, feedback, recommendations, or other input relating to the ReturnPro Materials
          (“Feedback”), you hereby grant ReturnPro the full, unencumbered right to use, incorporate
          and otherwise fully exercise and exploit any such Feedback in connection with its products
          and services generally.
        </li>
        <li>
          &nbsp;&nbsp;<u>Marketing</u>. ReturnPro reserves the right to use your name and/or company
          name as a reference for marketing or promotional purposes on ReturnPro’s website and in
          other communication with existing or potential ReturnPro customers. To decline ReturnPro
          this right you need to email support@returnpro.com stating that you wish not to be used as
          a reference.
        </li>
        <li>
          &nbsp;&nbsp;<u>Third Party Sites</u>. The Self-Service Products may contain links to other
          websites or resources for Your convenience , features and functionalities that may link or
          provide Authorized Users with access to third party content which is completely
          independent of ReturnPro, including platforms, websites, directories, servers, networks,
          systems, information and databases, applications, software, programs, products or
          services, and the Internet in general. You acknowledge and agree that ReturnPro is not
          responsible or liable for (i) the availability or accuracy of such sites or resources or
          (ii) the content, advertising, or products on or available from such sites or resources,
          the agreements that may govern the use of such sites or resources, or the privacy policies
          and data collection, use or retention practices of such sites. All use of the links is
          done at Your and Your Authorized Users’ own risk. If you enable, access or use any
          platforms or other third-party services in connection with the Self-Service Products, you
          agree that your access and use of such third-party services is governed by the terms and
          conditions of such third-party service provider, and ReturnPro does not endorse, is not
          responsible or liable for, and makes no representations as to any aspect of such
          third-party services.
        </li>
      </ol>
    </li>
  )
}

const SeventeenthNumber = () => {
  return (
    <li>
      &nbsp;&nbsp;<u>Definitions</u>.
      <ol>
        <br />
        <li>
          &nbsp;&nbsp;&quot;Access Credentials&quot; means any user name, identification number,
          password, license or security key, security token, PIN, or other security code, method,
          technology, or device, used alone or in combination, to verify an individual&apos;s
          identity and authorization to access and use the Self-Service Products.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Action&quot; means any claim, action, cause of action, demand, lawsuit,
          arbitration, inquiry, audit, notice of violation, proceeding, litigation, citation,
          summons, subpoena, or investigation of any nature, civil, criminal, administrative,
          regulatory, or other, whether at law, in equity, or otherwise.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Affiliate&quot; of a Person means any other Person that directly or
          indirectly, through one or more intermediaries, controls, is controlled by, or is under
          common control with, such Person. The term &quot;control&quot; (including the terms
          &quot;controlled by&quot; and &quot;under common control with&quot;) means the direct or
          indirect power to direct or cause the direction of the management and policies of a
          Person, whether through the ownership of voting securities, by contract, or
          otherwise/ownership of more than 50% of the voting securities of a Person.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Authorized Users&quot; means Your employees, consultants, contractors,
          and agents (a) who are authorized by You to access and use the Services under the rights
          granted to You pursuant to this Agreement; and (b) for whom access to the Services has
          been purchased hereunder. Your Authorized Users may include Your vendors and other
          third-parties.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Documentation&quot; means any manuals, instructions, or other documents
          or materials that the ReturnPro provides or makes available to You in any form or medium
          and which describe the functionality, components, features, or requirements of the
          ReturnPro Materials, including any aspect of the installation, configuration, integration,
          operation, use, support, or maintenance thereof.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Harmful Code&quot; means any software, hardware, or other technology,
          device, or means, including any virus, worm, malware, or other malicious computer code,
          the purpose or effect of which is to (a) permit unauthorized access to, or to destroy,
          disrupt, disable, distort, or otherwise harm or impede in any manner any (i) computer,
          software, firmware, hardware, system, or network; or (ii) any application or function of
          any of the foregoing or the security, integrity, confidentiality, or use of any data
          Processed thereby; or (b) prevent You or any Authorized User from accessing or using the
          Self-Service Products or ReturnPro Systems as intended by this Agreement. Harmful Code
          does not include any ReturnPro Disabling Device.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Intellectual Property Rights&quot; means any and all registered and
          unregistered rights granted, applied for, or otherwise now or hereafter in existence under
          or related to any patent, copyright, trademark, trade secret, database protection, or
          other intellectual property rights laws, and all similar or equivalent rights or forms of
          protection, in any part of the world.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Law&quot; means any statute, law, ordinance, regulation, rule, code,
          order, constitution, treaty, common law, judgment, decree, or other requirement of any
          federal, state, local, or foreign government or political subdivision thereof, or any
          arbitrator, court, or tribunal of competent jurisdiction.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Losses&quot; means any and all losses, damages, deficiencies, claims,
          actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of
          whatever kind, including reasonable attorneys&apos; fees and the costs of enforcing any
          right to indemnification hereunder.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Person&quot; means an individual, corporation, partnership, joint
          venture, limited liability entity, governmental authority, unincorporated organization,
          trust, association, or other entity.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Process&quot; means to take any action or perform any operation or set
          of operations that the SaaS Services are capable of taking or performing on any data,
          information, or other content[, including to collect, receive, input, upload, download,
          record, reproduce, store, organize, compile, combine, log, catalog, cross-reference,
          manage, maintain, copy, adapt, alter, translate, or make other derivative works or
          improvements, process, retrieve, output, consult, use, perform, display, disseminate,
          transmit, submit, post, transfer, disclose, or otherwise provide or make available, or
          block, erase, or destroy]. &quot;Processing&quot; and &quot;Processed&quot; have
          correlative meanings.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Retailer User&quot; means a third-party Person granted access to Your
          account as an Authorized User with the ability to: (i) review and set return policies,
          (ii) review, approve or reject claims and (iii) communicate with You regarding the same.
        </li>
        <li>
          &nbsp;&nbsp;&quot;ReturnPro Disabling Device&quot; means any software, hardware, or other
          technology, device, or means (including any back door, time bomb, time out, drop dead
          device, software routine, or other disabling device) used by ReturnPro or its designee to
          disable Your or any Authorized User&apos;s access to or use of the Self-Service Products
          automatically with the passage of time or under the positive control of ReturnPro or its
          designee.
        </li>
        <li>
          &nbsp;&nbsp;&quot;ReturnPro Materials&quot; means the Self-Service Products,
          Specifications, Documentation, and ReturnPro Systems and any and all other information,
          data, documents, materials, works, and other content, devices, methods, processes,
          hardware, software, and other technologies and inventions, including any deliverables,
          technical or functional descriptions, requirements, plans, or reports, that are provided
          or used by ReturnPro or any Subcontractor in connection with the Self-Service Products or
          otherwise comprise or relate to the Self-Service Products or ReturnPro Systems. For the
          avoidance of doubt, ReturnPro Materials include Resultant Data and any information, data,
          or other content derived from ReturnPro&apos;s monitoring of Your access to or use of the
          Services, but do not include Your Data.
        </li>
        <li>
          &nbsp;&nbsp;&quot;ReturnPro Personnel&quot; means all individuals involved in the
          performance of Services as employees, agents, or independent contractors of ReturnPro or
          any Subcontractor.
        </li>
        <li>
          &nbsp;&nbsp;&quot;ReturnPro Systems&quot; means the information technology infrastructure
          used by or on behalf of ReturnPro in performing the Self-Service Products, including all
          computers, software, hardware, databases, electronic systems (including database
          management systems), and networks, whether operated directly by ReturnPro or through the
          use of third-party services.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Representatives&quot; means, with respect to a party, that party&apos;s
          and its Affiliates&apos; employees, officers, directors, consultants, agents, independent
          contractors, subcontractors, and legal advisors.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Resultant Data&quot; means data and information related to Your use of
          the Services that is used by ReturnPro in an aggregate and anonymized manner, including to
          compile statistical and performance information related to the provision and operation of
          the Services.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Self-Service Products&quot; means the software-as-a-service offerings,
          consignment offerings, and other products and services detailed on Exhibit A attached to
          this Agreement, as may be amended from time to time.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Specifications&quot; means the specifications for the Self-Service
          Products, if any, set forth in Exhibit A.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Third-Party Materials&quot; means materials and information, in any form
          or medium, including any open-source or other software, documents, data, content,
          specifications, products, equipment, or components of or relating to the Services that are
          not proprietary to ReturnPro.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Your Data&quot; means information, data, and other content, in any form
          or medium, that is collected, downloaded, or otherwise received, directly or indirectly,
          from You or an Authorized User by or through the Services. For the avoidance of doubt,
          Your Data does not include Resultant Data or any other information reflecting the access
          or use of the Services by or on behalf of You or any Authorized User.
        </li>
        <li>
          &nbsp;&nbsp;&quot;Your Systems&quot; means the Your information technology infrastructure,
          including computers, software, hardware, databases, electronic systems (including database
          management systems), and networks, whether operated directly by You or through the use of
          third-party services.
        </li>
        <li>
          &nbsp;&nbsp;“Your Products” means the tangible goods and property that You designate as a
          consigned product through the Self-Service Products, as may be amended from time to time.
        </li>
      </ol>
    </li>
  )
}

const TermsOfUse = () => {
  return (
    <section className="flex pt-28">
      <Container>
        <Typography.H2 className="pb-12">User Agreement</Typography.H2>
        <div className="mb-4 text-xl font-normal text-nav-item-text">
          The Recon Group User Agreement (“Agreement”) is between The Recon Group LLP, dba ReturnPro
          (“ReturnPro”, “We,” “Us” or “Our”) and the individual or entity accepting this Agreement
          (“You” or “Your”) and governs Your use of our Self-Service Products (as defined herein).
          You and ReturnPro may be referred to herein as a “Party” or collectively the “Parties.”
          <br />
          <br />
          This Agreement does not have to be signed to be binding and is effective as of the earlier
          date that You first click “I agree” or “Create account” (or similar button or checkbox) or
          otherwise use or access the Self-Service Products (the “Effective Date”). By accepting
          this Agreement, You acknowledge that You have read, understood and agree to be bound by
          this Agreement. If You sign up for an account on behalf of a company or entity, You
          represent and warrant that You have full legal authority to bind the applicable entity or
          company to this Agreement and for all purposes herein, “You” and “Your” shall mean such
          entity. IF YOU DO NOT HAVE AUTHORITY TO BIND SUCH ENTITY, OR IF YOU DO NOT AGREE TO THIS
          AGREEMENT, YOU MUST NOT ACCEPT THIS AGREEMENT AND MUST NOT USE THE SELF-SERVICE PRODUCTS.
          ReturnPro may modify this Agreement at any time and such modification or update shall
          become effective immediately upon your next access to, or transaction with, the
          Self-Service Products.
          <br />
          <br />
          ReturnPro provides an end-to-end return management platform that enables merchants or
          sellers of record to enhance their customers product return experience. Subject to the
          payment of the applicable fees, You may procure through the self-service platform, such
          Self-Service Products pursuant to the terms of this Agreement.
          <br />
          <br />
          <ol>
            <FirstNumber />
            <TwoNumber />
            <ThirdNumber />
            <FourthNumber />
            <FifthNumber />
            <SixthNumber />
            <SeventhNumber />
            <EighthNumber />
            <NinthNumber />
            <TenthNumber />
            <EleventhNumber />
            <TwelfthNumber />
            <ThirteenthNumber />
            <FourteenthNumber />
            <FifteenthNumber />
            <SixteenthNumber />
            <SeventeenthNumber />
            If User has any questions or comments concerning the User Agreement, please contact
            ReturnPro at the following address:{' '}
            <a className="underline" href="mailto:support@returnpro.com">
              support@returnpro.com
            </a>
          </ol>
        </div>
      </Container>
    </section>
  )
}

export default TermsOfUse

export const Head = () => <SEO title="User Agreement" />
